// @flow

import styled from '@emotion/styled'
import { useFormik } from 'formik'
import React from 'react'
import { useAuth } from '@toggl/auth'
import { colors, media, fonts } from '@toggl/style'
import { Hero, text, Form, Button } from '@toggl/ui'

import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'invalid email address'
  }

  return errors
}

const ForgotPasswordPage = () => {
  const [ready, auth] = useAuth()
  const [done, setDone] = React.useState(false)
  const HeroComponent = React.useMemo(
    () => ({ children }) => {
      return (
        <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
          {children}

          <StyledWrapper>
            <Hero.Title centered>
              <Rotalic>{done ? 'Super' : 'Dang'}.</Rotalic>
            </Hero.Title>
            <SmallTitle>
              {done
                ? 'An email containing instructions to reset your password has been sent.'
                : 'Forgot your password, eh?'}
            </SmallTitle>
          </StyledWrapper>
        </Hero.Wrapper>
      )
    },
    [done]
  )
  const formik = useFormik<{ email: string, form: void }>({
    initialValues: {
      email: '',
    },
    validate,
    validateOnChange: false,
    // $FlowFixMe useFormik type definition is outdated
    onSubmit: async ({ email }) => {
      try {
        await auth.forgotPasswordInit({ email })
        setDone(true)
      } catch (e) {
        formik.setFieldError('form', e.message)
      }
    },
  })

  return (
    <IndexLayout>
      <LayoutTrack Hero={HeroComponent}>
        <Root>
          <StyledForm error={formik.errors.form} onSubmit={formik.handleSubmit}>
            <Form.Input
              id="email"
              label={<label htmlFor="email">Email</label>}
              name="email"
              type="email"
              placeholder={'Email'}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={
                formik.errors.email ? <div>{formik.errors.email}</div> : null
              }
            />
            <Button.Primary
              type="submit"
              disabled={!ready || formik.isSubmitting}
            >
              {done ? 'Resend email' : 'Reset password'}
            </Button.Primary>
          </StyledForm>
        </Root>
      </LayoutTrack>
    </IndexLayout>
  )
}
const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};
  color: ${(p) => p.theme.primary};
`

const StyledWrapper = styled(Hero.ContentWrapper)`
  ${media.mq[1]} {
    padding: 76px 20px 168px 20px;
  }
`
const Root = styled.div`
  padding-bottom: 130px;
`
const SmallTitle = styled.div`
  ${text.paragraph2};
  color: ${colors.almostWhite};
  text-align: center;
  margin-bottom: 15px;

  ${media.mq[1]} {
    margin-bottom: 30px;
  }
`

const StyledForm = styled(Form.Wrapper)`
  padding: 26px 16px;
  ${media.mq[1]} {
    width: 673px;
    padding: 58px 45px 64px 45px;
    background-color: ${colors.darkPurple};
    margin: 0 auto;
    transform: translateY(-30px);
    z-index: 200;
    position: relative;
  }
`

export default ForgotPasswordPage
